<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <!-- TODO: This "based on another task" approach could be achieved in future with data-driven tasks, but probably is not feasible yet. -->
      <p class="mb-2">
        Based on the molecular formula and the UN/DBE you calculated, check all the functional
        groups that could be present in compound B.
      </p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA2M2Q2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: 'alcohol', value: 'alcohol'},
        {text: 'amine', value: 'amine'},
        {text: 'ether', value: 'ether'},
        {text: 'aldehyde', value: 'aldehyde'},
        {text: 'ketone', value: 'ketone'},
        {text: 'ester', value: 'ester'},
        {text: 'carboxylic acid', value: 'acid'},
        {text: 'nitrile', value: 'nitrile'},
        {text: 'benzene ring', value: 'benzene'},
        {text: 'alkene', value: 'alkene'},
        {text: 'alkyne', value: 'alkyne'},
        {text: 'ring', value: 'ring'},
        {text: 'epoxide', value: 'epoxide'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
